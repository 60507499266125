import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Button, Icon } from 'components/shared';
import { alwaysFalse, doNothing, emptyArray, emptyObject, identity, isTrue } from "utils/constants";
import { copyTextToTitle } from "utils/dom";
import { useDedup, useDerivedState, useTouchDevice } from "utils/hooks";
import { DisplayBreadcrumb, useDisplayBreadcrumbs } from "utils/hooks/breadcrumbs";
import { useSearchHighlight, useSearchIsActive, useSearchPhraseAsState } from "utils/hooks/search";
import { tran } from "utils/language";
import { APPS, getPage, pages } from "utils/pages";
import { SidebarFilterInput } from "../SidebarFilterInput";
import { SidebarListItem } from "./SidebarListItem";

import { SidebarEntry, SidebarEntries, SubMenuPages } from "../types";
import { Link } from 'react-router-dom';
import { useShellContext } from "components/shell/shellContext";
import bootstrap from "bootstrap";

type SidebarListProps = {
}

export function SidebarList() {
  const { requirementsMet } = useShellContext();
  
  // const breadcrumbs = useDisplayBreadcrumbs();
  
  const entries = useDerivedState(makeSidebarEntries, requirementsMet);
  //const subMenuPages = emptySubMenuPages; //useDerivedState(getSubMenuPagesFromBreadcrumbs, breadcrumbs, entries);
  
  const listRef = useRef<HTMLDivElement>(null);
  // const [isListScrollable, setListScrollable] = useState(false);
  // const [expandedItems, setExpandedItems] = useState<string[]>([]);
  

  // useEffect(() => {
  //   checkIsListScrollable();
  //
  //   window.addEventListener('resize', checkIsListScrollable);
  //   return () => {
  //     window.removeEventListener('resize', checkIsListScrollable);
  //   };
  // }, [expandedItems]);
  //
  // const checkIsListScrollable = () => {
  //   if (listRef.current) {
  //     setListScrollable(listRef.current.scrollHeight > listRef.current.clientHeight);
  //   }
  // };
  //
  // const scrollToTop = () => {
  //   if (listRef.current) {
  //     listRef.current.scrollTo(0, 0);
  //   }
  // }
  
  const searching = useSearchIsActive();
  
  // UWAGA przy refaktoringu: <SidebarFilterInput/> musi być zawsze renderowany w tym komponencie,
  //       bo jak damy w różnych to będzie się przemontowywał i focus gubił
  
  if (searching) {
    return <>
      <div className="main-sidebar__entry main-sidebar__entry--category main-sidebar__entry--active" key="hdr">
        <Icon className="main-sidebar__entry-icon" name="search" type="primary"/>
        <div className="main-sidebar__entry-name one-liner" onMouseEnter={copyTextToTitle}>{tran("unique.search")}</div>
      </div>
      <SidebarFilterInput key="filter"/>
      <SidebarListSearch entries={entries} />
    </>
  }

  return <>
    <SidebarFilterInput key="filter" />
    <div className="main-sidebar__list main-sidebar__list--main" ref={listRef} key="list">
      {entries.map((item) => (
        <SidebarListItem
          key={item.fullPath}
          item={item} />
      ))}
      {!bootstrap.beta && localStorage.getItem("beta") && exitBeta}
      {/*{isListScrollable && (*/}
      {/*  <Button*/}
      {/*    style={{ margin: '5px' }}*/}
      {/*    size="small"*/}
      {/*    icon="chevron-up"*/}
      {/*    variant="icon"*/}
      {/*    onClick={scrollToTop}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  </>
}

const exitBeta = <button className={`rst main-sidebar__entry main-sidebar__entry--category`} style={{ cursor: "pointer" }} onClick={() => { window.localStorage.removeItem('beta'); window.location.reload(); }}>
  <div className="main-sidebar__entry-name one-liner">Wyłącz funkcje beta</div>
</button>;

function SidebarListSearch({ entries }: { entries: SidebarEntries }) {
  const highlight = useSearchHighlight();
  const flattenedEntries = useDerivedState(flattenEntriesForSearch, entries, highlight);
  
  return <div className="main-sidebar__list main-sidebar__list--main" key="list">
    {flattenedEntries}
  </div>;
}

function flattenEntriesForSearch(entries: SidebarEntries, highlight: (s: string) => React.ReactNode, results: React.ReactNode[] = []) {
  for (const entry of entries) {
    results.push(<SidebarSearchResult
        key={entry.fullPath}
        item={entry}
        highlight={highlight}
    />);
    entry.children && flattenEntriesForSearch(entry.children, highlight, results);
  }
  return results;
}

function SidebarSearchResult({ item, highlight }: any) {
  const { fullPath, icon, searchName, children } = item;
  
  if (!fullPath)
    console.warn("item has no path", item);
  
  const isActive = !!useRouteMatch(fullPath!);
  
  // wykrywamy czy element został podświetlony (<mark></mark>) lub zapytanie jest puste i wyświetlamy wszystko
  const highlighted = highlight(searchName);
  const x = highlighted?.props?.children;
  const matches = typeof highlighted === "string" || (Array.isArray(x) ? x.some(el => el.type === "mark") : false);
  if (!matches)
    return null;
  
  return <Link className={`rst main-sidebar__entry main-sidebar__entry--normal ${isActive ? "main-sidebar__entry--active" : ""}`} to={fullPath!} aria-expanded={false}>
    <Icon className="main-sidebar__entry-icon" name={item.icon} type="primary" />
    <div className="main-sidebar__entry-name one-liner" onMouseEnter={copyTextToTitle}>{highlighted}</div>
  </Link>;
}

function makeSidebarEntries(requirements: Set<string>): SidebarEntries {
  return APPS.map(app => pagesOfApp(app.id, requirements)).filter(isTrue);
}

function pagesOfApp(appName: string, requirements: Set<string>): SidebarEntry | null {
  const page = getPage(appName);
  if (!page.sidebar || !page.checkRequirements(requirements))
    return null;
  
  let results = (Object.keys(page.children || emptyObject) as string[])
    .map(subName => pagesOfApp(`${appName}.${subName}`, requirements))
    .filter(isTrue);
  
  if (page.sort) {
    results.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
  }
  
  return {
    fullPath: page.fullPath,
    icon: page.icon,
    displayName: page.displayName,
    searchName: page.searchName,
    sortKey: page.sortKey,
    tooltip: page.description,
    children: results.length ? results : undefined,
  };
}

function findDeepestPage(pages: SidebarEntries, breadcrumbs: string[]): SidebarEntry | null {
  let deepestPage: SidebarEntry | null = null;
  
  for (const breadcrumb of breadcrumbs) {
    for (const page of Object.values(pages)) {
      if (page.fullPath && breadcrumb.includes(page.fullPath)) {
        deepestPage = page;
        if (page.children) {
          const foundDeepestPage = findDeepestPage(page.children || emptyArray, breadcrumbs);
          if (foundDeepestPage) {
            deepestPage = foundDeepestPage;
          }
        }
      }
    }
  }
  
  return deepestPage;
}

function getIndexWithFullPath(breadcrumbsUrls: string[], page: SidebarEntry | null): number {
  if (page && page.fullPath) {
    return breadcrumbsUrls.findIndex(url => page.fullPath && url.includes(page.fullPath));
  } else {
    return 0;
  }
}

const emptySubMenuPages = { target: "", items: [] }; // TODO: usunąć albo użyć breadcrumbów

function getSubMenuPagesFromBreadcrumbs(breadcrumbs: readonly DisplayBreadcrumb[], _pages: SidebarEntries) {
  const subMenuPages: SubMenuPages = { target: "", items: [] };
  const filteredBreadcrumbs = breadcrumbs.filter(breadcrumb => breadcrumb.url !== "/");
  const breadcrumbsUrls = filteredBreadcrumbs.map(breadcrumb => breadcrumb.url);
  
  const lastKnownPage = findDeepestPage(_pages, breadcrumbsUrls);
  const targetIndex = getIndexWithFullPath(breadcrumbsUrls, lastKnownPage);
  
  if (lastKnownPage) {
    const breadcrumbsWithoutKnownPages = filteredBreadcrumbs.slice(targetIndex + 1);
    
    if (breadcrumbsWithoutKnownPages.length && lastKnownPage.fullPath) {
      subMenuPages.target = lastKnownPage.fullPath;
      subMenuPages.items = breadcrumbsWithoutKnownPages;
    }
  }
  
  return subMenuPages;
}
