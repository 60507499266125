import React from "react";
import PropTypes from "prop-types";

import { Icon } from "./";

class Tag extends React.PureComponent {
  static Group = function Group(props) {
    return <div className="tag-group">{props.children}</div>;
  };
  render() {
    console.log("Tag render", this.props);
    const shadow = this.props.shadow ? "shadow" : "";
    const inverted = this.props.inverted ? "inverted" : "";
    const inline = this.props.inline ? "inline" : "";
    const badge = this.props.badge ? "badge" : "";
    const animate = this.props.animate ? (shadow === "" ? "animate" : "") : "";
    const compact = this.props.compact ? "compact" : "";
    const colorClass = this.props.color ? this.props.color : "primary"; 

    const icon = this.props.icon ? (
      !React.isValidElement(this.props.icon) ? (
        <Icon id="tagIcon" name={this.props.icon} />
      ) : (
        this.props.icon
      )
    ) : (
      ""
    );

    return (
      <div
        title={this.props.title}
        style={this.props.style}
        tabIndex={this.props.tabIndex ? this.props.tabIndex : 0}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        role="button"
        aria-label={this.props.title}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={`tag ${compact} ${inline} ${
          badge.length > 0 ? badge : `${shadow} ${inverted}`
        } ${animate} ${colorClass} ${this.props.className} `}
        onClick={this.props.onClick}
      >
        {icon}
        {this.props.children}
      </div>
    );
  }
}

export default Tag;

Tag.propTypes = {
  shadow: PropTypes.bool,
  inverted: PropTypes.bool,
  badge: PropTypes.bool,
  animate: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inline: PropTypes.bool,
  compact: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  color: PropTypes.oneOf(["primary", "error", "warning", "confirm"])
};

Tag.defaultProps = {
  inline: false,
  color: "primary",
};
